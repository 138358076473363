"use client";
import React from 'react';
import Image from 'next/image';
import styled, { keyframes, css } from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { Container, Box } from '@mui/material';
import Feature1_img from '../../../../public/Index_Page/features/feature1.svg';
import Feature1_img_mobile from '../../../../public/Index_Page/features/feature1_mobile.svg';
import Cooking_img from '../../../../public/Index_Page/features/cooking.svg';
import Time_img from '../../../../public/Index_Page/features/time.svg';
import Dish_img from '../../../../public/Index_Page/features/dish.svg';
import { HeadingText, ParagraphText } from '../../../ui/TextFonts';
import { variables } from '../../../styles/variables';

const slideInFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const FeatureSection = styled(Box)`
  padding: 2rem 0;
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  @media (max-width: 768px) {
    padding: 0;
  }
`;

const ImageWrapper = styled(Box)`
  position: relative;
  width: 62%;
  height: auto;

  @media (max-width: 768px) {
    display: block;
    width: 425px;
    margin-left: -25px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const ImageWrapperDesktop = styled(Box)`
  position: relative;
  width: 60%;
  height: auto;

  @media (max-width: 768px) {
    display: none;
  }

  @media (min-width: 768px) {
    display: block;
  }
`;

const FormWrapper = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 600px;
  width: 100%;
  justify-content: center;
`;

const FeatureList = styled(Box)`
  display: grid;
  gap: 16px;
  margin-top: 40px;

  div {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
  }
`;

const AnimationHeading = styled(HeadingText)`
opacity: 0;
  ${props => props.inView && css`
    animation: ${slideInFromLeft} 1s ease-out forwards;
  `}
`;

const AnimationParagraphText = styled(ParagraphText)`
opacity: 0;
  ${props => props.inView && css`
    animation: ${slideInFromLeft} 1s ease-out forwards;
  `}
`;

const FeatureListItem = styled.div`
  display: flex;
  align-items: center;
  opacity: 0;
  ${props => props.inView && css`
    animation: ${slideInFromLeft} 1s ease-out forwards;
  `}
`;

export default function FeaturePage() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <Box component="div" sx={{ mt: 8, px: { xs: 0, sm: 3, md: 8 } }} id="features">
      <Container maxWidth="xl">
        <FeatureSection ref={ref}>
          <ImageWrapper>
            <Image
              src={Feature1_img_mobile}
              alt="Feature image mobile"
              layout="responsive"
            />
          </ImageWrapper>
          <ImageWrapperDesktop>
            <Image
              src={Feature1_img}
              alt="Feature image desktop"
              layout="responsive"
            />
          </ImageWrapperDesktop>
          <FormWrapper>
            <AnimationHeading inView={inView}>
              Treat Your Taste Buds to the <span style={{ color: `${variables.primaryColor}` }}>Best in Town</span>
            </AnimationHeading>
            <AnimationParagraphText inView={inView} style={{ animationDelay: '1s' }}>
              Indulge in a variety of mouth-watering meals, prepared fresh and delivered hot right to your door.
            </AnimationParagraphText>
            <FeatureList>
              <FeatureListItem inView={inView} style={{ animationDelay: '1.5s' }}>
                <Image src={Cooking_img} />
                <ParagraphText style={{ marginLeft: '10px', fontSize: '14px', fontWeight: '500', color: '#000000' }}>
                  Choose from a wide selection of dishes from top local restaurants.
                </ParagraphText>
              </FeatureListItem>
              <FeatureListItem inView={inView} style={{ animationDelay: '2s' }}>
                <Image src={Time_img} />
                <ParagraphText style={{ marginLeft: '10px', fontSize: '14px', fontWeight: '500', color: '#000000' }}>
                  Get your favorite meals delivered in record time.
                </ParagraphText>
              </FeatureListItem>
              <FeatureListItem inView={inView} style={{ animationDelay: '2.5s' }}>
                <Image src={Dish_img} />
                <ParagraphText style={{ marginLeft: '10px', fontSize: '14px', fontWeight: '500', color: '#000000' }}>
                  Tailor your order just the way you like it.
                </ParagraphText>
              </FeatureListItem>
            </FeatureList>
          </FormWrapper>
        </FeatureSection>
      </Container>
    </Box>
  );
}
