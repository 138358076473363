import React from 'react';
import styled, { keyframes } from 'styled-components';
import Image from 'next/image';
import { Container, Box } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import { HeadingText, ParagraphText } from '../../../ui/TextFonts';
import Bike_img from '../../../../public/Index_Page/hero/bike.svg';
import Time_img from '../../../../public/Index_Page/hero/time.svg';
import Guarentee_img from '../../../../public/Index_Page/hero/guarentee.svg';
import Food_img from '../../../../public/Index_Page/hero/food.svg';
import Background_img from '../../../../public/Index_Page/hero/background.png';
import Background_img_mobile from '../../../../public/Index_Page/hero/background_mobile.svg';
import PlayStore_img from '../../../../public/footer/Google_play.svg';
import AppStore_img from '../../../../public/footer/Apple_store.svg';
import { variables } from '../../../styles/variables';

const slideInFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const HeroBackground = styled.div`
  position: relative;
  overflow: hidden;
  height: 100vh;

  @media (max-width: 767px) {
    display: none;
  }
`;

const BackgroundImage = styled(Image)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
`;

const HeroContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  z-index: 2;
  
  @media (max-width: 767px) {
    flex-direction: column;
    text-align: center;
  }
`;

const TextSection = styled.div`
  max-width: 40%;
  color: white;
  z-index: 2;
  margin-left: 60px;
  display: grid;
  gap: 30px;
  
  @media (max-width: 767px) {
    max-width: 100%;
    margin-left: 40px;
  }
`;

const Icons = styled.div`
  margin-top: 20px;
`;

const Icon = styled.a`
  display: inline-block;
  margin-right: 10px;

  img {
    width: 170px;
    height: auto;
  }

  @media (max-width: 767px) {
    margin-right: 5px;
  }
`;

const HeroContainer = styled(Box)`
  padding: 2rem 4rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;

  @media (min-width: 1024px) {
    flex-direction: row;
    gap: 4rem;
  }

  @media (max-width: 767px) {
    padding: 1rem;
    margin-top: 48px;
  }
`;

const HeroContentBox = styled(Box)`
  width: 100%;
  text-align: justify;
  display: grid;
  gap: 20px;

  @media (min-width: 430px) {
    width: 45%;
    text-align: left;
  }
`;

const HeroCount = styled(Box)`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  align-items: center;
  justify-content: center;

  @media (min-width: 767px) {
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
  }
`;

const CountItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const CountText = styled.p`
  font-size: 32px;
  color: ${variables.primaryColor};
  font-weight: 700;
  font-family: "Poppins", Helvetica;
  margin: 0;

  @media (min-width: 767px) {
    font-size: 48px;
  }
`;

const FeatureList = styled(Box)`
  display: grid;
  gap: 16px;
  margin-top: 40px;

  div {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
  }
`;

const ImageWrapper = styled(Box)`
  position: relative;
  width: 62%;
  height: auto;

  @media (max-width: 767px) {
    display: block;
    width: 425px;
    margin-left: -14px
  }

  @media (min-width: 767px) {
    display: none;
  }
`;

const AnimationHeading = styled(HeadingText)`
  animation: ${slideInFromLeft} 1s ease-out forwards;
`;

const AnimationParagraphText = styled(ParagraphText)`
  animation: ${slideInFromLeft} 1s ease-out forwards;
  opacity: 0;
`;

const FeatureListItem = styled.div`
  display: flex;
  align-items: center;
  animation: ${slideInFromLeft} 1s ease-out forwards;
  opacity: 0;
`;

export default function HeroSection() {
  const { ref: headingRef, inView: headingInView } = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const { ref: textRef, inView: textInView } = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  const { ref: featureRef, inView: featureInView } = useInView({
    triggerOnce: true,
    threshold: 0.1
  });

  return (
    <Box component="div" id="home">
      <Container maxWidth="xl" style={{ padding: '0px' }}>
        <HeroBackground>
          <BackgroundImage src={Background_img} alt="Background Image" layout="fill" />
          <HeroContent>
            <TextSection>
              <AnimationHeading
                ref={headingRef}
                style={{ animationPlayState: headingInView ? 'running' : 'paused' }}
              >
                Get What You Need, <span style={{ color: `${variables.primaryColor}` }}> When You Need It</span>
              </AnimationHeading>
              <AnimationParagraphText
                ref={textRef}
                style={{ animationPlayState: textInView ? 'running' : 'paused', animationDelay: '1s' }}
              >
                From groceries to gourmet meals, Pappad brings the best of your town right to your doorstep, faster than ever.
              </AnimationParagraphText>
              <FeatureList>
                <FeatureListItem
                  ref={featureRef}
                  style={{ animationPlayState: featureInView ? 'running' : 'paused', animationDelay: '2s' }}
                >
                  <Image src={Guarentee_img} />
                  <ParagraphText style={{ marginLeft: '10px', fontSize: '14px', fontWeight: '500', color: '#000000' }}>
                    Best Price Guaranteed
                  </ParagraphText>
                </FeatureListItem>
                <FeatureListItem
                  ref={featureRef}
                  style={{ animationPlayState: featureInView ? 'running' : 'paused', animationDelay: '3s' }}
                >
                  <Image src={Time_img} />
                  <ParagraphText style={{ marginLeft: '10px', fontSize: '14px', fontWeight: '500', color: '#000000' }}>
                    Fastest Delivery
                  </ParagraphText>
                </FeatureListItem>
                <FeatureListItem
                  ref={featureRef}
                  style={{ animationPlayState: featureInView ? 'running' : 'paused', animationDelay: '4s' }}
                >
                  <Image src={Food_img} />
                  <ParagraphText style={{ marginLeft: '10px', fontSize: '14px', fontWeight: '500', color: '#000000' }}>
                    Your Local Favorites
                  </ParagraphText>
                </FeatureListItem>
              </FeatureList>
              <Icons>
                <Icon href="https://play.google.com/store/search?q=pappad&c=apps&hl=en" target="_blank" rel="noopener noreferrer">
                  <Image src={PlayStore_img} alt="Play Store" />
                </Icon>
                <Icon href="https://apps.apple.com/in/app/pappad-delivery-app/id1609559334" target="_blank" rel="noopener noreferrer">
                  <Image src={AppStore_img} alt="App Store" />
                </Icon>
              </Icons>
            </TextSection>
            <Image src={Bike_img} alt="Bike" style={{ maxWidth: '60%', height: 'auto', zIndex: 2 }} />
          </HeroContent>
        </HeroBackground>

        <ImageWrapper>
          <Image src={Background_img_mobile} />
          <TextSection>
            <HeadingText>Get What You Need, <span style={{ color: `${variables.primaryColor}` }}> When You Need It</span></HeadingText>
            <ParagraphText>
              From groceries to gourmet meals, Pappad brings the best of your town right to your doorstep, faster than ever.
            </ParagraphText>
            <FeatureList>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Image src={Guarentee_img} />
                <ParagraphText style={{ marginLeft: '10px', fontSize: '14px', fontWeight: '500', color: '#000000' }}>
                  Best Price Guaranteed
                </ParagraphText>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Image src={Time_img} />
                <ParagraphText style={{ marginLeft: '10px', fontSize: '14px', fontWeight: '500', color: '#000000' }}>
                  Fastest Delivery
                </ParagraphText>
              </div>
              <div style={{ display: 'flex', alignItems: 'center' }}>
                <Image src={Food_img} />
                <ParagraphText style={{ marginLeft: '10px', fontSize: '14px', fontWeight: '500', color: '#000000' }}>
                  Your Local Favorites
                </ParagraphText>
              </div>
            </FeatureList>
            <Icons>
              <Icon href="https://play.google.com" target="_blank" rel="noopener noreferrer">
                <Image src={PlayStore_img} alt="Play Store" />
              </Icon>
              <Icon href="https://www.apple.com/app-store/" target="_blank" rel="noopener noreferrer">
                <Image src={AppStore_img} alt="App Store" />
              </Icon>
            </Icons>
          </TextSection>
        </ImageWrapper>

        <HeroContainer>
          <HeroContentBox>
            <HeadingText style={{ textAlign: 'left' }}>
              The best food delivery app in your town
            </HeadingText>
            <ParagraphText style={{ fontSize: '16px' }}>
              Join thousands of happy customers who trust Pappad for their daily needs. We partner with the top local restaurants and reliable delivery partners to ensure you get the best service, every time.
            </ParagraphText>
          </HeroContentBox>
          <HeroCount>
            <CountItem>
              <CountText>10000+</CountText>
              <ParagraphText>Customers</ParagraphText>
            </CountItem>
            <CountItem>
              <CountText>500+</CountText>
              <ParagraphText>Restaurant Partners</ParagraphText>
            </CountItem>
            <CountItem>
              <CountText>400+</CountText>
              <ParagraphText>Delivery Partners</ParagraphText>
            </CountItem>
          </HeroCount>
        </HeroContainer>
      </Container>
    </Box>
  );
}
