"use client";
import { useRouter } from 'next/navigation';
import React, { useState, useEffect } from 'react';
import { Box, Container, Button, Grid, Tooltip, Typography } from '@mui/material';
import { HeadingText, ParagraphText } from '../../../ui/TextFonts';
import styled, { keyframes, css } from 'styled-components';
import { useInView } from 'react-intersection-observer';
import axios from 'axios';
import Image from 'next/image';
import { variables } from '../../../styles/variables';
const cheerio = require('cheerio');

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const slideUp = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
`;

const AnimatedHeading = styled(HeadingText)`
  opacity: 0;
  animation: ${fadeIn} 1s ease-out forwards;
  ${props => props.inView && css`
    animation: ${slideUp} 1s ease-out forwards;
  `}
`;

const AnimatedParagraph = styled(ParagraphText)`
  opacity: 0;
  font-size: 16px;
  font-weight: 400;
  width: 584px;
  margin: 20px auto;
  animation: ${fadeIn} 1s ease-out forwards;
  ${props => props.inView && css`
    animation: ${slideUp} 1s ease-out forwards;
  `}

  @media (max-width: 768px) {
    padding: 0px;
  }

  @media (max-width: 430px) {
    padding: 0px;
    width: 350px;
  }
`;

const BlogImage = styled(Image)`
  border-radius: 20px;
  object-fit: cover;
  height: 200px;
  width: 100%;
`;

const BlogBox = styled(Box)`
  background-color: var(--colors-brand-50, #FFF4E7);
  border-radius: 8px;
  padding: 16px;
  height: 460px;
`;

export default function BlogsComponent() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });
  const router = useRouter();
  const [blogPost, setBlogPost] = useState([]);
  const [showAll, setShowAll] = useState(false);
  const postsToShow = showAll ? blogPost : blogPost.slice(0, 3);

  useEffect(() => {
    fetchPosts();
  }, []);

  const fetchPosts = async () => {
    try {
      const response = await axios.get("https://blogs.pappad.com/wp-json/wp/v2/posts");
      setBlogPost(response.data);
    } catch (error) {
      console.error('Error fetching posts:', error);
    }
  };

  const convertImg = (contentHtml) => {
    const $ = cheerio.load(contentHtml);
    const imgElement = $('img').first();
    const imgSrc = imgElement.attr('src');

    return imgSrc || null;
  };

  const truncateDescription = (description) => {
    const words = description.split(' ');
    return words.length > 50 ? words.slice(0, 50).join(' ') + '...' : description;
  };

  const redirectBlogPage = (e) => {
    router.push(`/blogs/${e.slug}`)
  }

  return (
    <Box component="div" sx={{ mt: 8, px: { xs: 2, sm: 3, md: 5 } }} id="blogs">
      <Container maxWidth="xl">
        <Box ref={ref} textAlign="center">
          <AnimatedHeading inView={inView}>
            Tasty Insights and <span style={{ color: `${variables.primaryColor}` }}>Fresh Tips</span>
          </AnimatedHeading>
          <AnimatedParagraph inView={inView}>
            Stay in the know with our latest updates, expert tips, and insights into the world of food and delivery. Explore our blog to learn more about how Pappad is revolutionizing the way you shop and dine.
          </AnimatedParagraph>
          <Grid container spacing={4} sx={{ p: { xs: 0, md: 3 } }}>
            {postsToShow.map((post) => {
              const imgSrc = convertImg(post.content.rendered);
              return (
                <Grid item xs={12} sm={6} md={4} key={post.id}>
                  <BlogBox onClick={() => { redirectBlogPage(post) }}>
                    {imgSrc && (
                      <BlogImage
                        src={imgSrc}
                        alt={post.title.rendered}
                        width={500}
                        height={300}
                      />
                    )}
                    <Box sx={{ mt: 2, textAlign: 'left' }}>
                      <ParagraphText style={{ fontSize: '13px', fontWeight: '400' }}>{new Date(post.date).toLocaleDateString()}</ParagraphText>

                      <Tooltip
                        title={post.title.rendered}
                        arrow
                        placement="top"
                        disableInteractive={false}
                      >
                        <Typography sx={{
                          height: '150px',
                          textAlign: 'justify',
                          color: "#1e1e1e",
                          fontFamily: '"Poppins", Helvetica',
                          fontSize: '24px',
                          fontWeight: '600',
                          whiteSpace: 'pre-wrap',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          cursor: 'pointer',
                        }}>
                          {truncateDescription(post.title.rendered)}
                        </Typography>
                      </Tooltip>
                      <ParagraphText style={{ fontSize: '12px', fontWeight: '700', color: `${variables.primaryColor}`, marginTop: '24px' }}>READ NOW</ParagraphText>
                    </Box>
                  </BlogBox>
                </Grid>
              );
            })}
          </Grid>
          <Box sx={{ textAlign: 'center', mt: 4 }}>
            <Button
              variant="contained"
              color="primary"
              style={{ backgroundColor: `${variables.primaryColor}` }}
              onClick={() => setShowAll(!showAll)}
            >
              {showAll ? 'Show Less' : 'Show All'}
            </Button>
          </Box>
        </Box>
      </Container>
    </Box>
  );
}
