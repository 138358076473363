"use client";
import React from 'react';
import Image from 'next/image';
import styled, { keyframes, css } from 'styled-components';
import { Container, Box } from '@mui/material';
import { useInView } from 'react-intersection-observer';
import Feature2_img from '../../../../public/Index_Page/features/feature2.svg';
import Feature2_img_mobile from '../../../../public/Index_Page/features/feature2_mobile.svg';
import Fruit_img from '../../../../public/Index_Page/features/fruit.svg';
import Guarentee_img from '../../../../public/Index_Page/features/guarentee.svg';
import Reuse_img from '../../../../public/Index_Page/features/reuse.svg';
import { HeadingText, ParagraphText } from '../../../ui/TextFonts';
import { variables } from '../../../styles/variables';

const slideInFromLeft = keyframes`
  0% {
    opacity: 0;
    transform: translateX(-20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
`;

const FeatureSection = styled(Box)`
  padding: 2rem 0px;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin-bottom: 2rem;

  @media (min-width: 768px) {
    flex-direction: row;
  }

  @media (max-width: 768px) {
    padding: 0rem;
  }
`;

const ImageWrapper = styled(Box)`
  position: relative;
  width: 62%;
  height: auto;

  @media (max-width: 768px) {
    display: block;
    width: 425px;
    margin-left: -25px;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const ImageWrapperDesktop = styled(Box)`
  position: relative;
  width: 60%;
  height: auto;

  @media (max-width: 768px) {
    display: none;
  }

  @media (min-width: 768px) {
    display: block;
  }
`;

const FormWrapper = styled(Box)`
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  max-width: 600px;
  width: 100%;
  justify-content: center;
`;

const FeatureList = styled(Box)`
  display: grid;
  gap: 16px;
  margin-top: 40px;

  div {
    display: flex;
    align-items: center;
    img {
      margin-right: 10px;
    }
  }
`;

const AnimationHeading = styled(HeadingText)`
opacity: 0;
  ${props => props.inView && css`
    animation: ${slideInFromLeft} 1s ease-out forwards;
  `}
`;

const AnimationParagraphText = styled(ParagraphText)`
opacity: 0;
  ${props => props.inView && css`
    animation: ${slideInFromLeft} 1s ease-out forwards;
    animation-delay: 1s;
  `}
`;

const FeatureListItem = styled.div`
  display: flex;
  align-items: center;
  opacity: 0;
  ${props => props.inView && css`
    animation: ${slideInFromLeft} 1s ease-out forwards;
    animation-delay: ${props => props.delay}s;
  `}
`;

export default function FeatureClickAway() {
  const { ref, inView } = useInView({
    triggerOnce: true,
    threshold: 0.1,
  });

  return (
    <Box component="div" sx={{ mt: 8, px: { xs: 0, sm: 3, md: 8 } }} id="features-2">
      <Container maxWidth="xl">
        <FeatureSection ref={ref}>
          <ImageWrapper>
            <Image
              src={Feature2_img_mobile}
              alt="Feature image mobile"
              layout="responsive"
            />
          </ImageWrapper>
          <FormWrapper>
            <AnimationHeading inView={inView}>
              Fresh Groceries, Just a <span style={{ color: `${variables.primaryColor}` }}>Click Away</span>
            </AnimationHeading>
            <AnimationParagraphText inView={inView} style={{ animationDelay: '1s' }}>
              Get the best of fresh, local produce delivered to your home, hassle-free.
            </AnimationParagraphText>
            <FeatureList>
              <FeatureListItem inView={inView} delay={1.5}>
                <Image src={Fruit_img} />
                <ParagraphText style={{ marginLeft: '10px', fontSize: '14px', fontWeight: '500', color: '#000000' }}>
                  Access a broad range of fresh fruits, vegetables, and everyday essentials.
                </ParagraphText>
              </FeatureListItem>
              <FeatureListItem inView={inView} delay={2}>
                <Image src={Guarentee_img} />
                <ParagraphText style={{ marginLeft: '10px', fontSize: '14px', fontWeight: '500', color: '#000000' }}>
                  We source our products from trusted local suppliers to ensure freshness.
                </ParagraphText>
              </FeatureListItem>
              <FeatureListItem inView={inView} delay={2.5}>
                <Image src={Reuse_img} />
                <ParagraphText style={{ marginLeft: '10px', fontSize: '14px', fontWeight: '500', color: '#000000' }}>
                  Choose a delivery time that suits your schedule.
                </ParagraphText>
              </FeatureListItem>
            </FeatureList>
          </FormWrapper>
          <ImageWrapperDesktop>
            <Image
              src={Feature2_img}
              alt="Feature image desktop"
              layout="responsive"
            />
          </ImageWrapperDesktop>
        </FeatureSection>
      </Container>
    </Box>
  );
}
